import React from 'react';
import { RenderElement } from 'types/components';

type TranslationPlaceholderUtilityOptions = {
  // Option config that helps with the automated tests
  renderAsText?: boolean;
};

export function replaceTranslationPlaceholder(
  translation: string | undefined,
  replaceValues: Record<string, RenderElement | string>,
  options?: TranslationPlaceholderUtilityOptions
): RenderElement {
  if (!translation) return null;

  const translationSegments = translation.split(/(\{[^\}]+\})/g);

  const renderElements = translationSegments.map((segment) => {
    const placeholder = segment.match(/^\{([^\}]+)\}$/);

    if (!placeholder) return segment;

    const placeholderName = placeholder[1];
    const replaceValue = replaceValues[placeholderName];

    return React.isValidElement(replaceValue)
      ? replaceValue
      : String(replaceValue);
  });

  return options?.renderAsText ? (
    renderElements.join('')
  ) : (
    <>{renderElements}</>
  );
}

export type ExtractPlaceholderValueFn = (
  val: string,
  key: string
) => RenderElement;

export function replaceTranslationDynamicPlaceholder(
  translation: string,
  replaceValues: Record<
    string,
    ExtractPlaceholderValueFn | RenderElement | string
  >,
  options?: TranslationPlaceholderUtilityOptions
): RenderElement {
  if (!translation) return null;

  const translationSegments = translation.split(/(\{[^\}]+\})/g);

  const renderElements = translationSegments.map((segment) => {
    const placeholder = segment.match(/^\{([^\}]+)\}$/);

    if (!placeholder) return segment;

    const placeholderNameAndValue = placeholder[1];
    const placeholderName = placeholderNameAndValue.split(':')[0];
    const placeholderValue = placeholderNameAndValue.split(':')[1];
    const replaceMethod = replaceValues[placeholderName];

    return typeof replaceMethod === 'function'
      ? replaceMethod(placeholderValue, placeholderName)
      : String(replaceMethod);
  });

  return options?.renderAsText ? (
    renderElements.join('')
  ) : (
    <>{renderElements}</>
  );
}
