import { AccountProvider, Client } from '@collinsonx/constants/enums';
import useLocale from 'hooks/useLocale';

export const getCustomBrandName = (
  accountProvider?: string,
  membershipType?: string
) => {
  const translations = useLocale();
  if (membershipType && membershipType === Client.Mastercard_MCAE)
    return translations.generic.branding.MCAECustomBrandName;

  switch (accountProvider) {
    case AccountProvider.LK:
      return 'Lounge Key';
    case AccountProvider.PP:
      return 'Priority Pass';
    default:
      return 'Priority Pass';
  }
};
