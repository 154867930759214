import { ProductCategory } from '@collinsonx/utils';
import useGuestDetails from 'hooks/useGuestDetails';
import useLocale from 'hooks/useLocale';
import useProduct from 'hooks/useProduct';
import { RenderElement } from 'types/components';
import { getCustomBrandName } from 'utils/getCustomBrandName';
import { replaceTranslationPlaceholder } from 'utils/translations';

import usePayload from './payload';

type CopyText = RenderElement | undefined;

type CategoryConfig = {
  copy: Partial<{
    [key in ProductCategory]: {
      cancellationPolicy: {
        line1: CopyText;
        line2?: CopyText;
      };
      errors: {
        capacity: CopyText;
      };
      estimatedArrivalTimeExplanation: CopyText;
      guestsPolicy: CopyText;
      importantNotes: {
        one: CopyText;
        three: CopyText;
        two: CopyText;
      };
      terms: {
        line1: CopyText;
        line2: CopyText;
        link: CopyText;
      };
      timeSlotsTimeZoneDisclaimer: CopyText;
    };
  }>;
};

const useProductCategory = () => {
  const { excludedGuestFromPartyCountString } = useGuestDetails();
  const { payload } = usePayload();
  const {
    cancellationPeriod,
    category: productCategory,
    maxPartySize,
  } = useProduct();
  const { booking, fastTrack } = useLocale();

  const membership = getCustomBrandName(
    payload?.accountProvider,
    payload?.membershipType
  );

  const categoryConfig: CategoryConfig = {
    copy: {
      [ProductCategory.FastTrack]: {
        cancellationPolicy: {
          line1: fastTrack.policies.cancellation,
        },
        errors: {
          capacity: replaceTranslationPlaceholder(fastTrack.errors.capacity, {
            guests: maxPartySize,
          }),
        },
        estimatedArrivalTimeExplanation:
          fastTrack.estimatedArrivalTimeDescription,
        guestsPolicy: replaceTranslationPlaceholder(fastTrack.policies.guests, {
          guests: maxPartySize,
        }),
        importantNotes: {
          one: fastTrack.importantNotes.one,
          three: fastTrack.importantNotes.three,
          two: fastTrack.importantNotes.two,
        },
        terms: {
          line1: fastTrack.policies.terms.line1,
          line2: fastTrack.policies.terms.line2,
          link: fastTrack.policies.terms.link,
        },
        timeSlotsTimeZoneDisclaimer: fastTrack.timeSlotsDisclaimer,
      },
      [ProductCategory.Lounge]: {
        cancellationPolicy: {
          line1: replaceTranslationPlaceholder(
            booking.availableSlots.cancellationPolicy.descriptionLine1,
            { time: cancellationPeriod }
          ),
          line2: replaceTranslationPlaceholder(
            booking.availableSlots.cancellationPolicy.descriptionLine2,
            { time: cancellationPeriod }
          ),
        },
        errors: {
          capacity: replaceTranslationPlaceholder(
            booking.guestDetails.errors.capacity,
            {
              excluded: excludedGuestFromPartyCountString,
              guests: maxPartySize,
            }
          ),
        },
        estimatedArrivalTimeExplanation: booking.availableSlots.description,
        guestsPolicy: replaceTranslationPlaceholder(
          booking.guestDetails.description,
          { excluded: excludedGuestFromPartyCountString, guests: maxPartySize }
        ),
        importantNotes: {
          one: replaceTranslationPlaceholder(
            booking.confirmationPayment.outcome.succesful.importantNotes.notes
              .one,
            { membershipType: membership }
          ),
          three: replaceTranslationPlaceholder(
            booking.confirmationPayment.outcome.succesful.importantNotes.notes
              .three,
            { time: cancellationPeriod }
          ),
          two: booking.confirmationPayment.outcome.succesful.importantNotes
            .notes.two,
        },
        terms: {
          line1: booking.guestDetails.loungeTerms.line1,
          line2: booking.guestDetails.loungeTerms.line2,
          link: booking.guestDetails.loungeTerms.link,
        },
        timeSlotsTimeZoneDisclaimer: booking.availableSlots.arrivalDescription,
      },
    },
  };

  return {
    displayProductCopyOf: () => {
      if (productCategory) return categoryConfig.copy[productCategory];
      return categoryConfig.copy[ProductCategory.Lounge];
    },
  };
};

export default useProductCategory;
