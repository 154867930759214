import { WarningIcon } from '@collinsonx/design-system/assets/icons';
import Heading from '@collinsonx/design-system/components/heading/Heading';
import { Anchor, Box, Flex, Grid, Text } from '@collinsonx/design-system/core';
import { UseFormReturnType } from '@collinsonx/design-system/form';
import { BlockWrapper } from '@components/BlockWrapper/BlockWrapper';
import Notification from '@components/Notification';
import { sendMobileEvent } from '@lib';
import { MOBILE_ACTION_BACK } from 'config/constants';
import { GUEST_TYPES } from 'config/productDefaultConfig';
import useGuestDetails from 'hooks/useGuestDetails';
import useLocale from 'hooks/useLocale';
import useProduct from 'hooks/useProduct';
import useProductCategory from 'hooks/useProductCategory';
import React, { useCallback } from 'react';
import colors from 'ui/colour-constants';
import { replaceTranslationPlaceholder } from 'utils/translations';

import QuantityInput from '../QuantityInput';
import classes from './Guestinfo.module.css';

export interface GuestInfoProps {
  form: UseFormReturnType<any, any>;
  guestError: boolean;
  loading: boolean;
  referreUrl: string;
}

const GuestInfo = ({
  form,
  guestError,
  loading,
  referreUrl,
}: GuestInfoProps) => {
  const translations = useLocale();

  const { displayProductCopyOf } = useProductCategory();
  const { guests } = useProduct();
  const { availableGuestTypes, getPartyParameters } = useGuestDetails();

  const {
    maxAdultsCount,
    maxChildrenCount,
    maxInfantsCount,
    maxSeniorsCount,
    partySizeReached,
  } = getPartyParameters({
    [GUEST_TYPES.adults]: form.getInputProps(GUEST_TYPES.adults).value,
    [GUEST_TYPES.children]: form.getInputProps(GUEST_TYPES.children).value,
    [GUEST_TYPES.infants]: form.getInputProps(GUEST_TYPES.infants).value,
    [GUEST_TYPES.seniors]: form.getInputProps(GUEST_TYPES.seniors).value,
  });

  const handleClick = useCallback(() => {
    if (top) {
      if (referreUrl) {
        top.location.href = referreUrl;
      } else {
        const windowObj: any = window;
        sendMobileEvent(windowObj, MOBILE_ACTION_BACK);
      }
    }
  }, [referreUrl]);

  return (
    <BlockWrapper>
      <Heading as="h2" margin={0} padding={0}>
        {translations.booking.guestDetails.title}
      </Heading>
      <Flex align="top" direction="column" gap={8}>
        <Flex
          align="top"
          className={partySizeReached ? classes.warning : ''}
          direction="row"
          gap={8}
        >
          <Box pt={2}>
            <WarningIcon style={{ height: 21, width: 21 }} />
          </Box>
          <Text>{displayProductCopyOf()?.guestsPolicy}</Text>
        </Flex>
        {guestError ? (
          <Box className={classes.errorContainer}>
            <Notification>
              {displayProductCopyOf()?.errors.capacity}
            </Notification>
          </Box>
        ) : (
          ''
        )}
      </Flex>
      <Grid>
        {availableGuestTypes.adults && (
          <Grid.Col span={{ lg: 6 }}>
            <QuantityInput
              disabled={loading}
              label={
                translations.booking.guestDetails[GUEST_TYPES.adults].label
              }
              max={maxAdultsCount}
              min={1}
              onChange={form.getInputProps(GUEST_TYPES.adults).onChange}
              subText={
                replaceTranslationPlaceholder(
                  translations.booking.guestDetails.adults.description,
                  {
                    age: guests[GUEST_TYPES.adults]?.age,
                  }
                ) as string
              }
              value={form.getInputProps(GUEST_TYPES.adults).value}
            />
          </Grid.Col>
        )}
        {availableGuestTypes.children && (
          <Grid.Col span={{ lg: 6 }}>
            <QuantityInput
              disabled={loading}
              label={
                translations.booking.guestDetails[GUEST_TYPES.children].label
              }
              max={maxChildrenCount}
              min={0}
              onChange={form.getInputProps(GUEST_TYPES.children).onChange}
              subText={
                replaceTranslationPlaceholder(
                  translations.booking.guestDetails.children.description,
                  {
                    age: guests[GUEST_TYPES.children]?.age,
                  }
                ) as string
              }
              value={form.getInputProps(GUEST_TYPES.children).value}
            />
          </Grid.Col>
        )}
        {availableGuestTypes.infants && (
          <Grid.Col span={{ lg: 6 }}>
            <QuantityInput
              disabled={loading}
              label={
                translations.booking.guestDetails[GUEST_TYPES.infants].label
              }
              max={maxInfantsCount}
              min={0}
              onChange={form.getInputProps(GUEST_TYPES.infants).onChange}
              subText={
                replaceTranslationPlaceholder(
                  translations.booking.guestDetails.infants.description,
                  {
                    age: guests[GUEST_TYPES.infants]?.age,
                  }
                ) as string
              }
              value={form.getInputProps(GUEST_TYPES.infants).value}
            />
          </Grid.Col>
        )}
        {availableGuestTypes.seniors && (
          <Grid.Col span={{ lg: 6 }}>
            <QuantityInput
              disabled={loading}
              label={translations.booking.guestDetails.seniors.label}
              max={maxSeniorsCount}
              min={0}
              onChange={form.getInputProps(GUEST_TYPES.seniors).onChange}
              subText={translations.booking.guestDetails.seniors.description}
              value={form.getInputProps(GUEST_TYPES.seniors).value}
            />
          </Grid.Col>
        )}
      </Grid>
      <Text size="sm">
        {displayProductCopyOf()?.terms.line1}
        <Anchor color={colors.blue} onClick={handleClick}>
          {displayProductCopyOf()?.terms.link}
        </Anchor>{' '}
        {displayProductCopyOf()?.terms.line2}
      </Text>
    </BlockWrapper>
  );
};

export default GuestInfo;
