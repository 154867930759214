import { Box, BoxProps } from '@collinsonx/design-system/core';

import classes from '../../styles/SvgAdapter.module.css';

interface SvgAdapterProps {
  children: JSX.Element;
  fill?: string;
  style?: BoxProps['style'];
  width?: string;
}

function SvgAdapter({
  children,
  fill,
  style,
  width,
}: Readonly<SvgAdapterProps>) {
  const boxStyle = {
    ...style,
    '--svg-fill': fill,
    width,
  };

  return (
    <Box className={classes.svgContainer} style={boxStyle}>
      {children}
    </Box>
  );
}

export default SvgAdapter;
