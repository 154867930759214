import { TimeInput, TimeInputProps } from '@collinsonx/design-system/date';

interface TimeInputLabel extends TimeInputProps {
  isCapitalLetters?: boolean;
  isWhite?: boolean;
}

export default function TimeInputLabel({
  isCapitalLetters,
  isWhite = false,
  ...props
}: TimeInputLabel) {
  const inputColor = isWhite ? '#FFFFFF' : '';

  return (
    <>
      <TimeInput
        {...props}
        styles={{
          description: {
            marginBottom: '0',
            marginTop: '4px',
            order: 1,
          },
          input: {
            order: -1,
            textTransform: isCapitalLetters ? 'uppercase' : 'none',
          },
          label: {
            color: inputColor,
            order: -2,
            padding: '0 0 0.5rem 0',
          },
          root: {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      />
    </>
  );
}
