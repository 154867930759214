import { FC } from 'react';

import { flightInfoNotFoundError } from '.';
import { FlightInfoErrorHandlingProps } from './props';

const FlightInfoErrorHandling: FC<FlightInfoErrorHandlingProps> = ({
  hasError,
}) => hasError && flightInfoNotFoundError();

export default FlightInfoErrorHandling;
